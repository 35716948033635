import React, { useEffect, useState } from "react"
import { Container, TextWrapper, ButtonWrapper, GridWrapper } from "./style"
import {
  H2Title,
  Section,
  GrayBorder,
  OrangeSpan,
  P,
  AnimatedBackground,
  QuadrupleGrid,
} from "../../../globalStyle"
import Button from "../../UI/Button"
import Job from "./Job"
import FeatureCard from "./FeatureCard"
import { useTheme } from "styled-components"
import careerFeaturesData from "../../../data/components/careerFeatures"
import useJobsQuery from "../../../helpers/queries/jobsQuery"

const CareerComponent = () => {
  const [jobs, setJobs] = useState([])
  const jobsData = useJobsQuery()
  const theme = useTheme()

  useEffect(() => {
    let jobSlugs = []
    let jobSlugsWithTitles = []

    jobsData.allContentfulJob.edges.forEach(job => {
      let jobSlug = job.node.title.split(' ').join('-').split('.').join('').toLowerCase()
      let currentJobSlug = ""
      let counter = 1

      while (jobSlugs.includes(jobSlug)) {
        counter++
      }

      if (counter > 1) {
        currentJobSlug = jobSlug + counter
      } else {
        currentJobSlug = jobSlug
      }

      jobSlugs.push(currentJobSlug)
      jobSlugsWithTitles.push({ slug: currentJobSlug, title: job.node.title, isImportant: job.node.isImportant })
    
    })
    setJobs(jobSlugsWithTitles)
  }, [])

  return (
    <Section style={{ marginTop: "100px" }}>
      <Container>
        <H2Title marginLeft="15px">Career</H2Title>
      </Container>
      <GrayBorder />

      <Container>
        <H2Title style={{ marginBottom: "0px" }} marginLeft="15px" thin={true}>
          Join Oakfusion <OrangeSpan>Team</OrangeSpan>
        </H2Title>
        <TextWrapper>
          <P>
            Our mission is to create the best software in a great working
            atmosphere. Look at our job offers below.
          </P>
        </TextWrapper>
        <ButtonWrapper>
          <Button slug="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=cdf7c9be45f246f0a710c7a25fff4b90">
            Apply now
          </Button>
        </ButtonWrapper>
      </Container>
      <Container>
        {jobs.map((j, idx) => (
          <Job key={idx} slug={j.slug} title={j.title} isImportant={j.isImportant}/>
        ))}
      </Container>
      <GridWrapper>
        <AnimatedBackground />
        <Container style={{ zIndex: 400 }}>
          <H2Title color={theme.colors.white} center={true}>
            In Oakfusion you will find:
          </H2Title>
          <QuadrupleGrid>
            {careerFeaturesData.map(c => (
              <FeatureCard
                key={c.id}
                image={c.image}
                text={c.text}
              ></FeatureCard>
            ))}
          </QuadrupleGrid>
        </Container>
      </GridWrapper>
    </Section>
  )
}

export default CareerComponent
