import React from "react"
import { Icon } from "@iconify/react"
import { FeatureCardWrapper, ImageWrapper } from "./style"
import { P } from "../../../../globalStyle"
import IFeatureCard from "./types"
import { useTheme } from "styled-components"

const FeatureCard = (props: IFeatureCard) => {
  const { image, text } = props
  const theme = useTheme()
  return (
    <FeatureCardWrapper>
      <ImageWrapper>
        <Icon icon={image}></Icon>
      </ImageWrapper>
      <P color={theme.colors.white} center={true}>
        {text}
      </P>
    </FeatureCardWrapper>
  )
}

export default FeatureCard
