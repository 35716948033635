import React from "react"
import { JobWrapper } from "./style"
import { H3Title, OrangeSpan } from "../../../../globalStyle"
import { IJob } from "./types"
import { Link } from "gatsby"

const Job = (props: IJob) => {
  const { title, slug, isImportant } = props
  return (
    <JobWrapper isImportant={isImportant}>
      <Link to={"/job/" + slug}>
        <H3Title isDarkBlue={true}>
          {title}
          <OrangeSpan></OrangeSpan>
        </H3Title>
      </Link>
    </JobWrapper>
  )
}

export default Job
