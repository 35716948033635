import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const TextWrapper = styled.div`
  margin-top: 0;
  padding: 0 15px 0 15px;
`
export const ButtonWrapper = styled.div`
  margin-bottom: 50px;
`
export const GridWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
  margin-top: 70px;
  padding-bottom: 80px;
  padding-top: 20px;
`
