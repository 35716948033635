import chatBubblesQuestion20Regular from "@iconify-icons/fluent/chat-bubbles-question-20-regular"
import networkGlobeLine from "@iconify-icons/clarity/network-globe-line"
import armFlexOutline from "@iconify-icons/mdi/arm-flex-outline"
import groupIcon from "@iconify-icons/cil/group"

const careerFeaturesData = [
  {
    id: 1,
    image: groupIcon,
    text: "Great atmosphere created by our team",
  },
  {
    id: 2,
    image: chatBubblesQuestion20Regular,
    text: "Experienced colleagues that are always ready to help",
  },
  {
    id: 3,
    image: networkGlobeLine,
    text:
      "Various interesting projects all over the world (you can work in our office in Wrocław or on-site)",
  },
  {
    id: 4,
    image: armFlexOutline,
    text: "Help in improving your qualities",
  },
]

export default careerFeaturesData
