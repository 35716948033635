import styled, { css } from "styled-components"
import { IJobWrapper } from "./types"
import { myTheme } from "../../../../my-theme"

export const JobWrapper = styled.div<IJobWrapper>`
  margin: 15px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  & h3 {
    ${({
      isImportant,
      theme: {
        colors: { orange },
      },
    }) =>
      isImportant !== null &&
      isImportant === true &&
      css`
        color: ${orange} !important;
      `}
  }
`

