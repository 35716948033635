import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import CareerComponent from "../components/Sections/Career"

const Career = () => {
  return (
    <Layout>
      <Head
        description="Join Oakfusion Team 💻👨‍🎓 Apply now 🔸 Our mission is to create best software in a great working atmosphere. Look at our jobs offers ✅"
        pageTitle="Career | Our Job Offers | Join Oakfusion Team"
        keywords="software, oakfusion"
      />
      <CareerComponent />
    </Layout>
  )
}

export default Career
